<!-- -故障报修--详情 -->
<template>
    <div class="page_container ">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center ">
            <div class="box_head1 width_100">设备信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="设备ID：">
                    <el-input v-model="formInline.deviceID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <el-input v-model="formInline.deviceName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类别：">                  
                    <el-input v-model="formInline.deviceType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="规格型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="所在部门：">
                    <el-input v-model="formInline.deviceDepartment" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：">
                    <el-input v-model="formInline.deviceLocation" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">故障信息</div>
           <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="故障时间：">
                    <el-date-picker
                    v-model="formInline.failureTime"
                    class="width_180"
                    type="date"
                    placeholder="故障时间"
                    disabled  :clearable="false"
                    >
                    </el-date-picker>
                    <!-- <el-input v-model="formInline.failureTime" placeholder="发生时间" class="width_180" :formatter="formatDate" disabled></el-input> -->
                </el-form-item>
                <el-form-item label="操作人：">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作者电话：">
                    <el-input v-model="formInline.oprPhoneNumber" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="故障等级：">
                    <el-input v-model="formInline.failureLevel_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="故障类别：">
                    <el-input v-model="formInline.failureType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="当前审批顺序：">
                    <el-input v-model="formInline.authRoleName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="是否需要审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="是否停机：" class=" ">
                    <el-radio-group v-model="formInline.machineStop" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
              
                <!-- <div class="block">
                    <span class="demonstration">默认</span>
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </div> -->
                <el-form-item label="操作时间：">
                     <el-date-picker
                    v-model="formInline.oprTime"
                    class="width_180"
                    type="date"
                    placeholder="操作时间"
                    disabled  :clearable="false"
                    >
                    </el-date-picker>
                    <!-- <el-input v-model="formInline.oprTime" placeholder="操作时间" class="width_180" :formatter="formatDate" disabled></el-input> -->
                </el-form-item>
                <!-- <el-form-item label="项目ID">
                    <el-input v-model="formInline.projectID" placeholder="项目ID" class="width_180" disabled></el-input>
                </el-form-item> -->
            </el-form>
            <!-- <el-form :model="formInline" label-width="90px" style="width:65%; ">
                <el-form-item label="故障描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.failureDesc" resize="none" disabled></el-input>
                </el-form-item>
            </el-form> -->
             <el-form  :model="formInline" :label-width="formLabelWidth" style="width: 65%;">
                    <el-form-item label="故障描述：">
                        <el-input  type="textarea" :rows="5" resize="none" v-model="formInline.failureDesc" placeholder="" disabled></el-input>
                    </el-form-item>
                </el-form>
             <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                 <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" type="textarea" :rows="5" resize="none" placeholder=""  disabled></el-input>
                </el-form-item>
            </el-form>

            <!-- <div class="box_head1 width_100">审核信息</div> -->
            <!-- <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_24 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="审核人：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="审核意见：">
                    <el-radio-group v-model="radio1">
                        <el-radio :label="1">立即维修</el-radio>
                        <el-radio :label="2">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form> -->
            <!-- <el-form :model="formInline" label-width="110px" style="width:65%; " class="mg_top_30">
                <el-form-item label="处理意见：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline." resize="none"></el-input>
                </el-form-item>
            </el-form> -->
            <div class="flex  mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="colsePage">关闭</el-button>
                </div>
                <!-- <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button> -->
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
     import {
        failureReportQuery,
    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                formLabelWidth:'130px',
                value1: '',
                radio1: 1,
                formInline: {
                    user: '',
                    region: ''
                },
                ID:'',
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.failureReportQuery()
        },
        methods: {
             //查询单条
            async failureReportQuery() {
                this.ID= localStorage.getItem('select')
                console.log(this.ID)
                var param = {
                    ID:this.ID,
                }
                const selectRes = await failureReportQuery(param);
                this.formInline=selectRes.rows[0]
                console.log(this.formInline);
                
            },

            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            }

        }

    }
</script>
<style scoped>
  
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }
    
</style>